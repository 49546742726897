import IMG1 from '../../assets/car1.jpg';
import IMG2 from '../../assets/car2.jpg';
import IMG3 from '../../assets/car3.jpg';
import IMG4 from '../../assets/car4.jpg';
import IMG5 from '../../assets/car5.jpg';
import IMG6 from '../../assets/car6.jpg';
import IMG7 from '../../assets/car7.jpg';
import IMG8 from '../../assets/car8.jpg';


export default {
    IMG1,
    IMG2,
    IMG3,
    IMG4,
    IMG5,
    IMG6,
    IMG7,
    IMG8,
}