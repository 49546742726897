
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';


export default {
  gallery01,
  gallery02,
  gallery03,
  gallery04,
};